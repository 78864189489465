.sidebar {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  z-index: 1002;
  .center {
    justify-content: center !important;
    align-items: center !important;
  }
  .active {
    span {
      font-weight: 600 !important;
    }
    path {
      // padding: 20px;
      fill: #ffffff !important;
    }
  }

  .navigationItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    height: 40px !important;
    span {
      margin-left: 10px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      /* or 19px */

      display: flex;
      align-items: center;
      letter-spacing: 0.0075em;

      /* KS_E9EBEC */

      color: #e9ebec;
      &:hover {
        color: #e9ebec;
        font-weight: 600;
      }
    }
    svg {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    path {
      // padding: 20px;
      fill: #6fcf97;
    }
    .Icon {
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 24px !important;
      }
    }
  }
}

.brandWrapper {
  height: 50px;
  img {
    width: 102px;
    height: 28px;
    overflow-x: hidden;
  }
  a {
    display: flex;
    justify-content: flex-start !important;
    height: 50px;
  }
}
