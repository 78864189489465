.loadingContainer
{
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  width: 300px;
}

.InductionEditorContainer
{
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 4px;
    height: 100vh;
}

.pageItemWrapper
{
    height: 80vh;
    overflow-y: scroll;
    background-color: #fff;
}


.inductionMenuWrapper
{
    width: 132px;
}

.pagePreviewWrapper
{
    width: 475px;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;   
}

.pagePreviewContainer
{
    background-color: #fff;
    border: 3px solid #44C032;
    border-radius: 40px;
    overflow: hidden;
    max-width: 375px;
    margin: 0 auto;
    min-height: 700px;
    max-height: 1000px;
}

.pagePreviewContent
{
  overflow: hidden;
}

.pageEditorWrapper
{
    width: 50%;
    height: 90vh;
    overflow-y:scroll;
}

.pageEditorWrapper h3,
.pagePreviewWrapper h3
{
    text-align: center;
}

.pageEditorContainer
{
    background-color: #fff;
    padding: 30px;
}

.pageItem
{
    position: relative;
    background-color: #28353c;
    border-radius: 4px;
    min-height: 130px;
    width: 95px;
    padding: 10px;
    margin: 5px;
    border-radius: 6px;
    border: 4px solid #fff;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 300ms;
}

.pageItem:hover
{
    background-color: #40507E;
}

.pageItemSelected
{
    position: relative;
    background-color: #BB6BD9;
    border-radius: 4px;
    min-height: 130px;
    width: 95px;
    padding: 10px;
    margin: 5px;
    border-radius: 6px;
    border: 4px solid #fff;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);    
}


.pageItem img,
.pageItemSelected img
{
    width: 60%;
    margin-top: 30px;
}

.pageItemHeader
{
    position: absolute;
    top: -7px;
    left: 0px;
}
.pageItemHeader img
{
    width: 50%;
    margin: 0;
}

.pageItemTitle
{
    color: #fff;
    font-size: 0.8em;
    line-height: 1.2em;
    margin-top: 10px;
    display: block;
}

.ruleItemWrapper
{
    margin-top: 10px;
}

.ruleItem
{
    position: relative;
    background-color: #555;
    border-radius: 4px;
    min-height: 130px;
    width: 95px;
    padding: 10px;
    margin: 5px;
    border-radius: 6px;
    border: 4px solid #fff;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 0.8em;
    line-height: 1.2em;
}


.sectionItem
{
    position: relative;
    background-color: #555;
    border-radius: 4px;
    min-height: 95px;
    width: 85px;
    padding: 10px;
    margin: 5px;
    margin-left: 15px;
    border-radius: 6px;
    border: 4px solid #fff;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    font-size: 0.8em;
    line-height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
}

.darkButton {
    width: 121px;
    height: 40px;
    left: 1197px;
    background: #40507e;
    border: 1px solid #40507e;
    border-radius: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #ffffff;
    &:hover {
      background: #ffffff;
      color: #40507e;
    }
    &:focus {
      outline: none;
    }
  }

  .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 121px;
    height: 40px;
    background: #e26c82;
    border: 1px solid #e26c82;
    box-sizing: border-box;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    text-align: center;
    letter-spacing: 0.0075em;
    margin-left: 5px;
    color: #ffffff;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      outline: none;
    }
  }

  .ruleIconWrapper
  {
    width: 60%;
    margin: 4px auto;
  }

  .formHeading
  {
    text-align: left !important;
  }

  .formItemWrapper
  {
    display: flex;
    gap: 10px;
  }

  .icon
  {
    margin-top: 10px;
    margin-left: 10px;
  }

  .formLabel
  {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #40507e;
    margin-right: 36px;
    margin-left: 10px;
    margin-top: 20px;
    width: 100%;
}

.pagePreviewWrapper iframe
/*.pagePreviewWrapper div*/
{
   max-width: 375px; /* Iphone size */
}

.pagePreviewHeaderWrapper div
{
    max-width: 375px; /* Iphone size */
}

.qualificationCard
{
   width: 100%;
   background-color: #1cbaba;
   color: #fff;
   padding: 5px;
   border-radius: 6px;
   font-size: 22px;
}

.qualificationCardRadio
{
   display: flex;
}

.inputWrapper
{
    margin-top: 60px;
}

.qualificationCardRadioInput
{
    height: 40px;
    width: 20px;
}
.qualificationCardRadioLabel
{
    line-height: 40px;
    margin-left: 10px;
}

.buttonContainer {
    position: fixed;
    width: 100%;
    padding-left: 256px;
    height: 66px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
    display: flex;
  }
  
  .buttonWrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .darkButton {
    width: 121px;
    height: 40px;
    left: 1197px;
    background: #40507e;
    border: 1px solid #40507e;
    border-radius: 6px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:disabled {
      background: gray;
      color: #40507e;
    }
    &:hover {
      background: #ffffff;
      color: #40507e;
    }
    &:focus {
      outline: none;
    }
  }